<template>
  <div class="bg-grey">
    <b-container fluid>
      <b-row class="mt-5 text-center">
        <b-col class="offset-md-2 col-md-8 col-12 white-round my-5 py-5">
          <img src="@/assets/app-icons/green-tick.svg" alt="check-icon" />
          <h4 class="fpn-b py-3">Payment {{ message }}</h4>
          <p>You can now access your account and all it’s features!</p>
          <button @click="goTo" class="btn btn-primary btn-custom__one py-2">GO TO PROFILE</button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      redirect_flag: null,
      message: "successful",
    };
  },
  mounted() {
    if (this.$route.query.redirect_flag) {
      this.redirect_flag = this.$route.query.redirect_flag;
    }

    if (this.$route.query.authorized == "true") {
      this.message = "authorized";
    }
  },
  methods: {
    goTo() {
      if (this.redirect_flag == 1) {
        this.$router.push("/applicant-onboarding-3");
      } else if (this.redirect_flag == 2) {
        this.$router.push("/applicant-settings");
      } else if (this.redirect_flag == 3) {
        this.$router.push("/employer-settings");
      }
    },
  },
};
</script>
<style>
</style>

